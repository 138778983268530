<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 pt-5 ps-0">
          <div>和我们，做有挑战的事！</div>
        </div>
      </div>
    </div>
    <div class="container-lg px-4 content py-5">
      <div class="col-12">
        <a
          @click="$router.go(-1)"
          role="button"
          class="btn btn-outline-primary mb-3"
        >
          返回列表
        </a>
      </div>
      <div class="col-12 title fs-1 fw-bold">大模型应用开发工程师</div>
      <div class="mt-3">
        <div class="badge border text-primary border-primary">开发</div>
        <div class="ms-2 badge border text-primary border-primary">
          社会招聘
        </div>
      </div>
      <div class="description mt-3">
        <div class="fs-4 fw-bold my-4">岗位描述</div>
        <div class="mt-2">
          <ul class="list-unstyled mb-4">
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              负责基于大语言模型的金融智能体开发和优化。
            </li>
          </ul>
        </div>
      </div>

      <div class="requirement mt-3">
        <div class="fs-4 fw-bold my-4">岗位要求</div>
        <div class="mt-2">
          <ul class="list-unstyled mb-4">
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              扎实的编码能力，熟练掌握一门强类型语言（Rust/C++/C/Golang/Java等）；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              逻辑清晰，有较强的口头和文档表达能力；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              关注用户体验，能在开发工作中主动以用户视角思考和实现功能。
            </li>
          </ul>
        </div>
      </div>

      <div class="requirement mt-3">
        <div class="fs-4 fw-bold my-4">加分项</div>
        <div class="mt-2">
          <ul class="list-unstyled mb-4">
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              对代码的可维护性、性能细节有严格要求；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              了解证券基本知识，有交易经验则更佳；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              了解RAG/Function Calling/ReAct等增强生成式AI效果的常用范式；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              现有AI产品的重度用户，多个llm下调试prompt的一手经验；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              了解SFT，模型蒸馏等垂直模型生成和优化方法。
            </li>
          </ul>
        </div>
      </div>

      <div class="location mt-3">
        <div class="fs-4 fw-bold my-4">工作城市</div>
        <div class="mt-2">
          <span> 上海 </span>
          <span> 北京 </span>
          <span> 深圳 </span>
          <span> 成都 </span>
        </div>
      </div>

      <div class="mt-3">
        <div class="fs-4 fw-bold my-4">联系方式</div>
        <div class="mb-2">简历请发送至邮箱：recruit@ft.tech</div>
        <img src="../../assets/img/招聘求职.jpg" align="center" />
      </div>
      <div class="mt-3 d-flex justify-content-center">
        <a class="btn btn-primary px-5" href="mailto:recruit@ft.tech">
          简历投递
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Job9",
};
</script>
<style scoped lang="scss">
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/join/social_banner.jpg");
  background-position-x: 25%;
}
.container-lg {
  border-radius: 3rem;
}
a.btn {
  border-radius: 2rem;
}
img {
  width: 100px;
}
.cover-text > div {
  font-size: 3rem;
  font-weight: 400;
  filter: drop-shadow(2px 4px 6px black);
}
@media screen and (max-width: map-get($container-max-widths, lg)) {
  .cover-text {
    margin-top: 3rem !important;
  }
  .cover-text > div {
    font-size: 1.75rem !important;
    text-align: center;
  }
}
</style>
