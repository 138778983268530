var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"container-lg px-4 content py-5"},[_c('div',{staticClass:"col-12"},[_c('a',{staticClass:"btn btn-outline-primary mb-3",attrs:{"role":"button"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" 返回列表 ")])]),_c('div',{staticClass:"col-12 title fs-1 fw-bold"},[_vm._v("大模型应用开发工程师")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jumbotron-wrapper image"},[_c('div',{staticClass:"jumbotron container-lg px-4"},[_c('div',{staticClass:"animation cover-text mt-5 pt-5 ps-0"},[_c('div',[_vm._v("和我们，做有挑战的事！")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"badge border text-primary border-primary"},[_vm._v("开发")]),_c('div',{staticClass:"ms-2 badge border text-primary border-primary"},[_vm._v(" 社会招聘 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("岗位描述")]),_c('div',{staticClass:"mt-2"},[_c('ul',{staticClass:"list-unstyled mb-4"},[_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 负责基于大语言模型的金融智能体开发和优化。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"requirement mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("岗位要求")]),_c('div',{staticClass:"mt-2"},[_c('ul',{staticClass:"list-unstyled mb-4"},[_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 扎实的编码能力，熟练掌握一门强类型语言（Rust/C++/C/Golang/Java等）； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 逻辑清晰，有较强的口头和文档表达能力； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 关注用户体验，能在开发工作中主动以用户视角思考和实现功能。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"requirement mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("加分项")]),_c('div',{staticClass:"mt-2"},[_c('ul',{staticClass:"list-unstyled mb-4"},[_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 对代码的可维护性、性能细节有严格要求； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 了解证券基本知识，有交易经验则更佳； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 了解RAG/Function Calling/ReAct等增强生成式AI效果的常用范式； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 现有AI产品的重度用户，多个llm下调试prompt的一手经验； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 了解SFT，模型蒸馏等垂直模型生成和优化方法。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"location mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("工作城市")]),_c('div',{staticClass:"mt-2"},[_c('span',[_vm._v(" 上海 ")]),_c('span',[_vm._v(" 北京 ")]),_c('span',[_vm._v(" 深圳 ")]),_c('span',[_vm._v(" 成都 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("联系方式")]),_c('div',{staticClass:"mb-2"},[_vm._v("简历请发送至邮箱：recruit@ft.tech")]),_c('img',{attrs:{"src":require("../../assets/img/招聘求职.jpg"),"align":"center"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 d-flex justify-content-center"},[_c('a',{staticClass:"btn btn-primary px-5",attrs:{"href":"mailto:recruit@ft.tech"}},[_vm._v(" 简历投递 ")])])
}]

export { render, staticRenderFns }